import useAxios from '@/composables/use-axios';
import { AxiosResponse } from 'axios';

const { apiClient } = useAxios();

export interface ApiResourceInterface {
    query(query?: ApiQuery): Promise<AxiosResponse>;
    get(query: ApiGetQuery): Promise<IModel>;
    update(model: IModel): Promise<AxiosResponse>;
    save(model: IModel): Promise<AxiosResponse>;
    delete(model: IModel): Promise<AxiosResponse>;
}

// type Error = {
//   [key: number]: []
// }
//
// export interface ApiErrorsResponse {
//   message: string,
//   errors: [
//     [key: string]: Array<Error>
//   ]
// }

export interface IModel {
    [key: string]: any;
}

export type ApiQuery = {
    [key: string]: any;
};

type ApiGetQuery = {
    id: number;
};

export default class ApiResource implements ApiResourceInterface {
    protected uri: string;

    constructor(uri: string) {
        this.uri = uri;
    }

    async query(query?: ApiQuery): Promise<AxiosResponse> {
        return apiClient().get(this.uri, {
            params: query,
        });
    }

    async get(query: ApiGetQuery): Promise<IModel> {
        return apiClient().get(`${this.uri}/${query.id}`).then((response) => {
            return Promise.resolve(response.data.data);
        });
    }

    async update(model: IModel): Promise<AxiosResponse> {
        return apiClient().put(`${this.uri}/${model.id}`, model);
    }

    async save(model: IModel): Promise<AxiosResponse> {
        return apiClient().post(this.uri, model);
    }

    async delete(model: IModel): Promise<AxiosResponse> {
        return apiClient().delete(`${this.uri}/${model.id}`);
    }
}
